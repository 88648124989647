@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    
}

body {
    background-color: #111827;
    color: #fff;
}